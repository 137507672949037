import React from 'react'
import { graphql } from 'gatsby'
import MainWrapper from '../containers/MainWrapper'
import { LocaleContext } from '../components/Layout'
import SEO from '../components/SEO'
import Logo from '../components/Logo'
import Header from '../components/Header'
import ModalContainer from '../components/ModalContainer'
import Footer from '../components/Footer'

const EventsPage = ({ 
  data: { 
    banner, 
    mobilebanner, 
    footerbanner, 
    logo, 
    logohover 
  }, 
  pageContext: { 
    locale, 
    slug,
    content,
    alternates
  }, 
  location 
}) => {
  
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]

  const banners = {
    desktop: banner.edges[0].node.original.src,
    mobile: mobilebanner.edges[0].node.original.src,
    footerBanner: footerbanner.edges[0].node.original.src,
  }
  
  return (
    <React.Fragment>
      <SEO
        pathname={location.pathname}
        locale={locale}
        banner={banners.desktop}
        slug={slug}
      />
      <Header
        slug={slug}
        locale={locale}
        location={location}
        alternates={alternates}
      />
      <MainWrapper>
        <section
          className="intro desktop"
          style={{
            paddingTop: '80px',
            background: `url(${banners.mobile}) top no-repeat`,
            backgroundSize: 'cover',
          }}
        ></section>

        <section
          className="intro mobile auto-height"
        >
          <img src={banners.mobile} style={{ marginTop: "5rem", width: '100%' }} alt="David Jarre, Magic From Home" />
        </section>

        <section className="content regular" style={{ paddingBottom: "3rem" }}>
          <div style={{ margin: "-4rem 0 4rem 0" }}>
            <ModalContainer
              triggerText={i18n.buttons[3]}
              locale={locale}
              button={true}
            />
          </div>
          
          <div className="container magicFromHome">
            {
              content.map((item, index_) => {
                return (
                  <React.Fragment key={`p-frag-${index_}`}>
                    {
                      item.map((i, index) => {
                        return (
                          <div className="row" key={`p-${index}`}>
                            <div className="col nf np">
                              <div dangerouslySetInnerHTML={{ __html: i }} />
                            </div>
                          </div>
                        )
                      })
                    }
                  </React.Fragment>
                )
              })
            }
            
            <Logo
              logo={logo.edges[0].node.publicURL}
              logohover={logohover.edges[0].node.publicURL}
              className="page"
            />
          </div>
          <div style={{ margin: "-4rem 0 4rem 0" }}>
            <ModalContainer
              triggerText={i18n.buttons[3]}
              locale={locale}
              button={true}
            />
          </div>
          
        </section>

      </MainWrapper>
      <Footer />
    </React.Fragment>
  )
}

export default EventsPage
export const pageQuery = graphql`
  query Events {
    banner: allImageSharp(filter: {original: {src: {regex: "/background-mfh/"}}}) {
      edges {
        node {
          original {
            src
          }
        }
      }
    }
    mobilebanner: allImageSharp(filter: {original: {src: {regex: "/RM_76575/"}}}) {
      edges {
        node {
          original {
            src
          }
        }
      }
    }
    footerbanner: allImageSharp(filter: {original: {src: {regex: "/mfh-footer-banner-full/"}}}) {
      edges {
        node {
          original {
            src
          }
        }
      }
    }
    logo: allFile(filter: {relativePath: {eq: "david-jarre.com.jpg"}}) {
      edges {
        node {
          publicURL
        }
      }
    }
    logohover: allFile(filter: {relativePath: {eq: "vasarely.png"}}) {
      edges {
        node {
          publicURL
        }
      }
    }
  }
`